.buttons {
    margin-top: 20px;
}

.help {
    margin-bottom: 10px;
    font-size: 14px;

    &.error {
        color: red;
    }
}