.dialogContainer {
    padding: 0 20px 20px;

    .title {
        font-size: 17px;
        margin-bottom: 15px;
    }

    .btn {
        background-color: #9B9EAC;
        color: white;
        margin-top: 20px;
    }

    .btn:hover {
        background-color: #6e95eb;
    }

    span.content {
        font-size: 14px;
        line-height: 1;
        user-select: none;
    }

    .explanation {
        font-size: 13px;
        color: #9B9EAC;
    }

    .freeDays {
        margin: 15px 0 10px;
    }
}

div .icon {
    margin-top: 10px;
}
.icon:hover {
    cursor: pointer;
}