.error {
    color: red;
}

.expand {
    transform: rotate(0deg);
    transition-duration: .4s;
    margin-left: auto;
    border: 1px solid rgba(0, 0, 0, 0.12) !important;

    &.expandOpen {
        transform: rotate(180deg);
    }
}

.expandOuter {
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 8px;
}