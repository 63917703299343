.tariffSection {
    border-bottom: 1px solid #dadada;
    border-right: 1px solid #dadada;
    padding: 20px;
}

.checkbox {
    padding-bottom: 0 !important;
    position: relative;
    top: 6px;
}

.icon {
    position: relative;
    top: 3px;
    padding-left: 4px;
}

b {
    color: black !important;
}

.inDevelop {
    color: #b0b0b0 !important;
}