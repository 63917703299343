.popper {
    .tooltip {
        filter: drop-shadow(0px 10px 24px rgba(29, 42, 68, 0.12));
        background: var(--colorWhite);
        padding: 20px;
        max-width: 250px;
    }

    .arrow {
        color: var(--colorWhite);
        filter: drop-shadow(0px 10px 24px rgba(29, 42, 68, 0.12));
    }

    &.small {
        .tooltip {
            padding: 10px;
        }
    }
}

.title {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: var(--colorDarkGrey);
    margin-bottom: 8px;
}

.content {
    font-size: 12px;
    line-height: 14px;
    color: var(--colorGrey);
}