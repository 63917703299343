:root {
    --colorBg: #F5F6FA;
    --colorBlack: #282D30;
    --colorDarkGrey: #20253A;
    --colorGrey: #74798C;
    --colorLightGrey: #A0A5BA;
    --colorWhite: #fff;
    --colorBlockHover: #E5EEFF;
    --colorStatsBlock: rgba(179, 186, 216, 0.5);
    --colorScrollbar: #D8D8D8;

    --colorShopstat: #0054FE;
    --colorShopstatLight: #CEDFFF;
    --colorWildberries: #FA0D87;
    --colorWildberriesLight: #FBE9F3;
    --colorOzon: #0069FF;
    --colorOzonLight: #E5F0FF;
    --colorAliexpress: #0069FF;
    --colorAliexpressLight: #FCEBE9;
    --colorBeru: #7041EE;
    --colorBeruLight: #F1ECFD;

    --drawerWidth: 316px;
    --drawerMinWidth: 73px;

    --newDrawerWidth: 255px;
    --newDrawerMinWidth: 80px;
    --headerHeight: 70px;

    --analyticsRightWidth: 290px;

    --layoutTransition: .2s ease-in-out;
}

[data-theme="dark"] {
    --colorBg: #242424;
    --colorBlack: #ececec;
    --colorDarkGrey: #ececec;
    --colorGrey: #ececec;
    --colorLightGrey: #ececec;
    --colorWhite: #161616;
    --colorBlockHover: #333333;
    --colorStatsBlock: rgba(179, 186, 216, 0.5);
    --colorScrollbar: #666666;
}