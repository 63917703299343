.logInPage {
	width: 360px;
	margin: 100px auto;
	transition: .2s ease-in-out;
	padding: 16px;
	text-align: center;

	.title {
		font-size: 40px;
		font-weight: bold;
		line-height: 1.2;
		color: #000000;
		margin-bottom: 20px;
	}

	.description {
		font-size: 14px;
		line-height: 1.5;
		color: #666;
		margin-bottom: 32px;
	}

	.error {
		color: #f44336;
	}

	.regLink {
		color: #3f51b5;

		&:hover {
			color: #6e95eb !important;
		}

		&:visited {
			color: #3f51b5;
		}
	}

	.logIn {
		margin: 16px 0 8px;
	}
}
