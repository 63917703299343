.root {
    padding-left: 0 !important;

    .input {
        padding-left: 15px !important;
    }

    .select {
        .selectInput {
            padding-top: unset;
            padding-left: 20px;
            padding-bottom: unset;
            font-size: 14px;
            color: #777;
            height: 50px;
            line-height: 50px;
        }

        fieldset {
            border-width: 0 !important;
        }

        .selectIcon {
            font-size: 18px;
            padding: 1px;
        }

        .flag {
            min-width: 36px;
        }
    }

    .divider {
        border-color: rgba(0, 0, 0, 0.06);
    }
}

.popover {
    z-index: 130000000 !important;

    .paper {
        box-shadow: 0 8px 10px rgba(0,0,0,0.06);
    }
}