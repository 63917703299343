.dialog {
    :global(.MuiDialogContent-root) {
        padding: 20px;
    }
}

.medium {
    padding: 30px;

    .closeButton {
        margin: 48px;
    }

    .title {
        font-size: 20px;
    }
}

.title {
    padding: 20px 20px 20px !important;
}

.subtitle {
    font-size: 70%;
    line-height: 70%;
    font-weight: normal;
    margin-top: 10px;
}

.closeButton {
    position: absolute !important;
    right: 0;
    top: 0;
    margin: 10px;
}