@import "../styles/vars.pcss";

.container {
	font-size: 14px;
	display: flex;
	min-height: 100vh;
	background: #f5f5f5;
	overflow: hidden;
}

.navigator {
	flex-shrink: 0;
}

.content {
	flex: 1;
	display: flex;
	flex-direction: column;
}
