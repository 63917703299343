.tableToolBar {
    justify-content: space-between;
}

.productTable tr td:first-child {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 16px;
}
.productTable tr td:last-child {
    padding-top: 2px;
    padding-bottom: 2px;
}

.productTable tr th:first-child {
    padding-left: 16px;
}

.productTable tr th:last-child, .productTable tr td:last-child {
    padding-right: 16px;
}

.productTable tr td:nth-child(2) {
    padding-top: 2px;
    padding-bottom: 2px;
}

.filterTagsSelect {
    width: 150px;
    margin-right: 16px !important;
}

.dateFilter {
    margin-right: 16px !important;
}

.productFilterPanel {
    padding-top: 8px;
    padding-bottom: 8px;
}

.cardIcon {
    color: rgb(33, 150, 243);
}

.pagination {
    padding: 20px 0;
}
